<template>
    <v-card tile flat>
        <v-card-title>Locatie Registratie</v-card-title>
        <v-divider/>
        <PromiseContainer :promises.sync="promises">
            <template v-slot:default>
              <LocationBooleanParameter 
              :value="getLocationRegistrationConfig.ciaoConfig.ciaoEnabled" 
              @update:value="setCiaoEnabled"
              name="Check In and Out At Work RSZ"
              toolTip="Registraties en uren doorgegeven aan RSZ, aangifte van werken (project) en rijksregisternummer (werknemer) worden verplicht"
              />
            </template>
        </PromiseContainer>
    </v-card>
</template>

<script>
import PromiseContainer from "@/components/shared/PromiseContainer.vue"
import LocationBooleanParameter from "./LocationBooleanParameter.vue"

export default {
    name: "Location",
    components: {
        PromiseContainer,
        LocationBooleanParameter,
    },
    data() {
        return {
            promises: [
                this.$store.dispatch('locationRegistrationConfigModule/fetchLocationConfig')
            ],
        }
    },
    computed: {
        getLocationRegistrationConfig() {
            let locationRegistrationConfig = this.$store.getters['locationRegistrationConfigModule/getLocationConfig']
            // return locationRegistrationConfig, or its default value when null
            return locationRegistrationConfig ? locationRegistrationConfig 
            : {
                ciaoConfig: {
                    ciaoEnabled: false
                }
            }
        },
    },
    methods: {
        setCiaoEnabled(value) {
            this.$store.dispatch('locationRegistrationConfigModule/updateLocationConfig', {
                ...this.getLocationRegistrationConfig,
                ciaoConfig: {
                    ...this.getLocationRegistrationConfig.ciaoConfig,
                    ciaoEnabled: value
                }
            })
        },
    }
}
</script>