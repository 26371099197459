<template>
<v-row dense no-gutters class="paramRow">
  <v-col :cols="mobile ? 7 : 10" class="flex-grow-0 align-self-center">
  <span class="body-1 font-weight-medium text-xs-right d-flex ">
      <span class="text-truncate ">{{ name }}</span>
      <BasicToolTip v-if="toolTip" :description="toolTip" />
  </span>
  </v-col>
  <v-col :cols="mobile ? 5 : 2" dense align="right" class="flex-grow-0 ">
      <v-row no-gutters dense>
      <v-spacer></v-spacer>
      <v-col dense class="d-flex flex-grow-0 flex-shrink-1 align-center">
      <v-switch dense class="d-flex justify-end mt-2" hide-details="auto" :input-value="value"
            required autofocus @change="emitChange($event)" />
      </v-col>
  </v-row>
  </v-col>
</v-row>
</template>

<script>
import BasicToolTip from "@/components/shared/actions/BasicToolTip"

export default {
  name: "LocationBooleanParameter",
  components: {
    BasicToolTip
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    toolTip: {
      type: String,
      required: false,
    }
  },
  methods: {
    emitChange(newValue) {
      this.$emit('update:value', newValue)
    }
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
  }
}
</script>

<style>
.paramRow {
  height: 48px;
}

.paramRow+.paramRow {
  border-top: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
}
</style>